import * as React from "react";
import HaveYouSeenSection from "../../../components/HaveYouSeenSection/HaveYouSeenSection";
import CallToActionSection from "../../../components/CallToActionSection/CallToActionSection";
import Layout from "../../../components/Layout/Layout";
import TeamRolesSection from "../../../components/TeamRolesSection/TeamRolesSection";
import TestimonialSection from "../../../components/TestimonialSection/TestimonialSection";
import SubPageTitle from "../../../components/SubPageTitle/SubPageTitle";
import { teamMember } from "../../../components/SubPageTitle/subPageTitleContent";
import { teamMemberRoles } from "../../../components/TeamRolesSection/TeamRolesSectionContent";
import { SEO } from "../../../components/seo";
import { descriptions } from "../../../libs/seoContent";

const RestaurantTeamMember = () => {
  return (
    <Layout>
      {/* This the dummy page for you to put your components in */}
      <SubPageTitle content={teamMember} renderSect={null} />
      <TeamRolesSection content={teamMemberRoles} />
      <TestimonialSection
        pageTitle={"Restaurant Team Member"}
        className="reduced-padding-bottom"
        bottomRect={true}
      />
      <CallToActionSection />
      <HaveYouSeenSection page="restaurantTeamMember" />
    </Layout>
  );
};

export default RestaurantTeamMember;

export const Head = () => (
  <SEO title="Restaurant Team Member" description={descriptions.teamMember} />
);
