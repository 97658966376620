import * as React from "react";
import AnimationButton from "../AnimationButton/AnimationButton";
import { content } from "./callToActionContent";
import "./CallToActionSection.scss";

const CallToActionSection = () => {
  return (
    <div className="call-to-action-wrapper">
      <section className="call-to-action-container">
        <div className="call-to-action-text">
          <h2>{content.mainSubTitleText}</h2>
          <p>{content.mainSubTitleDescription}</p>
          <AnimationButton route={content.route} label={content.label} />
        </div>
        <div>
          <h4>{content.subTitleText1} </h4>
          <p>{content.subTitleDescription1}</p>
          <h4>{content.subTitleText2} </h4>
          <p>{content.subTitleDescription2}</p>
        </div>
      </section>
    </div>
  );
};

export default CallToActionSection;
