import { Globals } from "../../libs/globals";

type TCallToActionContent = {
  mainSubTitleText: string;
  mainSubTitleDescription: string;
  route: string;
  label: string;
  subTitleText1: string;
  subTitleDescription1: string;
  subTitleText2: string;
  subTitleDescription2: string;
};

export const content: TCallToActionContent = {
  mainSubTitleText: "Looking to grow?",
  mainSubTitleDescription:
    "When it comes to being a team member, our Buddies have been there, done it and worn the apron...",
  route: Globals.urls.teamMemberSearchURL,
  label: "Search for team member jobs",
  subTitleText1: "Buddies",
  subTitleDescription1:
    "Our Buddies pass on their skills and knowledge to everyone in the team. And if you’re looking to level up, we have Supervisor roles too...",
  subTitleText2: "Supervisors",
  subTitleDescription2:
    "Our Supervisors run the shifts, looking after our team members and making sure to get the best out of everyone.",
};
